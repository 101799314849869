import moment from 'moment-timezone';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  gql,
  useMutation,
  useQuery,
} from '../../../../../components/ApolloClient';
import { BookingStatusBadge } from '../../../../../components/BookingStatusBadge';
import { Fetcher } from '../../../../../components/Fetcher';
import { PremiumLabel } from '../../../../../components/PremiumLabel';
import { Tooltip } from '../../../../../components/Tooltip';
import { apiFetch, checkIfSuperAdmin } from '../../../../../utils';
import { boxFolderUrl } from '../../../../../utils/box';
import { isPartnerBooking } from '../../selectors';
import { locationForModal, ModalRoute } from '../ModalRoute';
import {
  SalesagentBookingClaimModal,
  SALESAGENT_BOOKING_CLAIM_MODAL_NAME,
} from './SalesagentBookingClaimModal';
import { PillBadgeList } from '../../../../../components/PillBadgeList';
import { useFetch } from '../../../../../components/useFetch';

const PURPOSES = ['business', 'personal'];

const Input = ({ label, extraClassName = '', hasChanged, ...rest }) => (
  <div className={'form-group ' + extraClassName}>
    {label && (
      <label>
        <small className="text-secondary">{label}</small>
      </label>
    )}
    <input
      className={`form-control ${hasChanged ? 'is-valid' : ''}`}
      {...rest}
    />
  </div>
);

const Rating = ({
  title,
  value,
  comment,
  session,
  bookingId,
  mutation,
  loading,
}) => {
  const content = (
    <div className="d-inline hover-rating">
      <small className="text-secondary mr-2">
        {title}: {value == null ? 'N/A' : value}
      </small>
      {checkIfSuperAdmin({ session }) === true && value != null && (
        <button
          className="btn btn-outline-danger btn-hover"
          disabled={loading === true}
          onClick={() =>
            mutation({ variables: { bookingId: bookingId } }).then(() =>
              window.location.reload()
            )
          }
        >
          x
        </button>
      )}
      <style jsx>{`
        .hover-rating {
          padding: 15px;
        }

        .hover-rating:hover {
          background-color: #dee0e3;
        }

        .hover-rating:hover .btn-hover {
          visibility: visible;
        }

        .btn-hover {
          visibility: hidden;
        }
      `}</style>
    </div>
  );

  if (comment == null || comment === '') return content;

  return (
    <Tooltip
      label={comment}
      style={{
        background: 'hsla(0, 0%, 0%, 0.75)',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        padding: '0.5em 1em',
      }}
      centered
      maxWidth={200}
    >
      {content}
    </Tooltip>
  );
};

export const BookingSummaryDetails = ({
  isNew,
  location,
  booking,
  bookingClone,
  bookingChangedKeys,
  session,
  onNameChange,
  onPartnerChange,
  onPurposeChange,
  onInternalNotesChange,
  onGalleryEnabledChange,
  onCentralizeEditingChange,
  onBookingPilotModeChange,
  onEnableVideoUploadChange,
  onPartnerRepresentativeChange,
  rootBasePath,
}) => {
  const {
    result: { results: partners } = { results: [] },
    isPending: partnersLoading,
  } = useFetch<{ count: number; results: { uid: string; name: string }[] }>({
    urlToFetch: '/api/v2/partners/summary',
    queryToFetch: { limit: 750 },
    session,
  });

  const [deleteProductRating, deleteProductRatingMutation] = useMutation(
    gql`
      mutation BookingProductRatingDeleteAsAdmin($bookingId: ID!) {
        bookingProductRatingDeleteAsAdmin(input: { bookingId: $bookingId }) {
          success
        }
      }
    `
  );

  const bookingTagsQuery = useQuery<{
    bookingById?: {
      id;
      bookingTags?: {
        edges: {
          id: string;
          name: string;
        }[];
      };
    };
  }>(
    gql`
      query BookingByIdFromBookingDetails($bookingId: ID!) {
        bookingById(id: $bookingId) {
          id
          bookingTags {
            edges {
              id
              name
            }
          }
        }
      }
    `,
    {
      variables: {
        bookingId: booking.uid,
      },
      skip: booking.uid == null,
    }
  );

  const tags = bookingTagsQuery.data?.bookingById?.bookingTags?.edges;

  const [deleteServiceRating, deleteServiceRatingMutation] = useMutation(
    gql`
      mutation BookingServiceRatingDeleteAsAdmin($bookingId: ID!) {
        bookingServiceRatingDeleteAsAdmin(input: { bookingId: $bookingId }) {
          success
        }
      }
    `
  );

  const [deleteRecommendRating, deleteRecommendRatingMutation] = useMutation(
    gql`
      mutation BookingRecommendRatingDeleteAsAdmin($bookingId: ID!) {
        bookingRecommendRatingDeleteAsAdmin(input: { bookingId: $bookingId }) {
          success
        }
      }
    `
  );

  const [
    callMaskingGetSessionAsReadOnly,
    callMaskingGetSessionAsReadOnlyMutation,
  ] = useMutation<
    {
      providerCallMaskingGetOrCreateSession?: {
        providerCallMaskingEnabled: boolean;
      };
    },
    { jobId: string }
  >(gql`
    mutation CallMaskingGetOrCreateSessionMutationFromUnresponsiveCustomer(
      $jobId: ID!
    ) {
      providerCallMaskingGetOrCreateSession(
        input: { bookingId: $jobId, readOnly: true }
      ) {
        providerCallMaskingEnabled
      }
    }
  `);

  React.useEffect(() => {
    if (bookingClone.uid != null) {
      callMaskingGetSessionAsReadOnly({
        variables: {
          jobId: bookingClone.uid,
        },
      });
    }

    return;
  }, [bookingClone.uid, callMaskingGetSessionAsReadOnly]);

  return (
    <div className="col d-flex flex-column">
      <div className="card flex-1">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="mb-0">Booking</h3>
              {booking.product_tier === 'premium' && <PremiumLabel />}
            </div>

            <div>
              {bookingClone.marketing_partner_name && (
                <span className="text-uppercase badge badge-secondary mr-2 d-inline">
                  {bookingClone.marketing_partner_name}
                </span>
              )}

              <Rating
                title="PQ"
                value={booking.product_rating}
                comment={booking.product_rating_note}
                session={session}
                mutation={deleteProductRating}
                loading={deleteProductRatingMutation.loading}
                bookingId={booking.uid}
              />
              <Rating
                title="CS"
                value={booking.service_rating}
                comment={booking.service_rating_note}
                session={session}
                mutation={deleteServiceRating}
                loading={deleteServiceRatingMutation.loading}
                bookingId={booking.uid}
              />
              <Rating
                title="NPS"
                value={booking.recommend_rating}
                comment={booking.recommend_rating_note}
                session={session}
                mutation={deleteRecommendRating}
                loading={deleteRecommendRatingMutation.loading}
                bookingId={booking.uid}
              />

              {booking != null && booking.uid != null && (
                <Fetcher
                  urlToFetch={`/api/v2/photo-editing-queue/${booking.uid}`}
                  session={session}
                >
                  {({
                    response: { editing_status } = {},
                  }: {
                    response: { editing_status?: string };
                  }) =>
                    (editing_status || null) && (
                      <small className="text-secondary mr-2">
                        (Editing status:{' '}
                        {{
                          waiting_raw: 'Waiting RAW',
                          pending_edits: 'Pending edits',
                          pending_qc: 'Pending QC',
                          completed: 'Completed',
                          needs_revision: 'Needs revision',
                        }[editing_status || ''] || editing_status}
                        )
                      </small>
                    )
                  }
                </Fetcher>
              )}

              {bookingClone.status && (
                <BookingStatusBadge status={bookingClone.status} />
              )}
            </div>
          </div>

          <div className="row">
            <div className="form-group col">
              <label>
                <small className="text-secondary">Booking purpose</small>
              </label>

              <select
                className={`form-control ${
                  bookingChangedKeys.partner_uid ? 'is-valid' : ''
                }`}
                value={bookingClone.purpose || ''}
                onChange={({ target: { value } }) =>
                  onPurposeChange({
                    purpose: value || null,
                  })
                }
                disabled={!isNew}
                style={{ textTransform: 'capitalize' }}
              >
                <Fragment>
                  <option value="">None</option>
                  {PURPOSES.map((purpose, index) => (
                    <option key={index} value={purpose}>
                      {purpose}
                    </option>
                  ))}
                </Fragment>
              </select>
            </div>
          </div>

          <div className="row">
            <div className="form-group col">
              <label>
                <small className="text-secondary">Partner</small>
              </label>
              <select
                className={`form-control ${
                  bookingChangedKeys.partner_uid ? 'is-valid' : ''
                }`}
                value={bookingClone.partner_uid || ''}
                onChange={({ target: { value } }) =>
                  onPartnerChange({
                    partner_uid: value || null,
                  })
                }
                disabled={!isNew}
              >
                {partnersLoading ? (
                  <option value="">Loading...</option>
                ) : (
                  <Fragment>
                    <option value="">None</option>
                    {partners.map(({ uid, name }) => (
                      <option key={uid} value={uid}>
                        {name}
                      </option>
                    ))}
                  </Fragment>
                )}
              </select>
            </div>

            {isPartnerBooking({ booking: bookingClone }) ? (
              <Fetcher
                urlToFetch={`/api/v2/partners/${bookingClone.partner_uid}/users`}
                session={session}
                processResponse={({
                  response: { results: partnerRepresentatives },
                }) =>
                  bookingClone.partner_representative_uid &&
                  !partnerRepresentatives.find(
                    ({ uid }) => uid === bookingClone.partner_representative_uid
                  )
                    ? apiFetch<{ uid: string; full_name: string }>(
                        `/api/v2/admin/users/${booking.partner_representative_uid}`,
                        { token: session.token }
                      ).then((user) => [
                        {
                          uid: user.uid,
                          full_name: user.full_name,
                          not_part_of_partner: true,
                        },
                        ...partnerRepresentatives,
                      ])
                    : partnerRepresentatives
                }
              >
                {({
                  response: partnerRepresentatives = [],
                  loading: partnerRepresentativesLoading,
                }) => (
                  <div className="form-group col">
                    <label>
                      <small className="text-secondary">
                        Partner representative
                      </small>
                    </label>

                    <select
                      className={`form-control ${
                        bookingChangedKeys.partner_representative_uid
                          ? 'is-valid'
                          : ''
                      }`}
                      value={bookingClone.partner_representative_uid || ''}
                      onChange={({ target: { value } }) =>
                        onPartnerRepresentativeChange({
                          partner_representative_uid: value || null,
                        })
                      }
                    >
                      {partnerRepresentativesLoading ? (
                        <option value="">Loading...</option>
                      ) : (
                        <Fragment>
                          <option value="">None</option>
                          {partnerRepresentatives.map(
                            ({ uid, full_name, not_part_of_partner }) => (
                              <option key={uid} value={uid}>
                                {full_name}
                                {not_part_of_partner &&
                                  ' (does not belong to partner)'}
                              </option>
                            )
                          )}
                        </Fragment>
                      )}
                    </select>
                  </div>
                )}
              </Fetcher>
            ) : (
              <Fetcher
                disabled={bookingClone.salesagent_uid == null}
                urlToFetch={`/api/v2/admin/users/${bookingClone.salesagent_uid}`}
                session={session}
              >
                {({
                  loading: salesAgentUserLoading,
                  response: salesAgentUser,
                }) => (
                  <div className="form-group col">
                    <label>
                      <small className="text-secondary">
                        Sales Agent{' '}
                        {!isNew && bookingClone.salesagent_uid == null && (
                          <small>
                            (
                            <Link
                              to={locationForModal({
                                location,
                                modal: {
                                  modalName: SALESAGENT_BOOKING_CLAIM_MODAL_NAME,
                                },
                              })}
                            >
                              claim
                            </Link>
                            )
                          </small>
                        )}
                      </small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={
                        (!salesAgentUserLoading &&
                          (salesAgentUser
                            ? [salesAgentUser.firstname, salesAgentUser.surname]
                                .filter((r) => !!r)
                                .join(' ')
                            : 'None')) ||
                        ''
                      }
                      onClick={(ev) => (ev.target as HTMLInputElement).select()}
                    />
                  </div>
                )}
              </Fetcher>
            )}
          </div>

          {isPartnerBooking({ booking: bookingClone }) && (
            <div className="row">
              <div className="form-group col">
                <label>
                  <small className="text-secondary">
                    Partner representative email
                  </small>
                </label>

                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={bookingClone.partner_representative_email || ''}
                  onClick={(ev) => (ev.target as HTMLInputElement).select()}
                />
              </div>

              <div className="form-group col">
                <label>
                  <small className="text-secondary">
                    Partner representative mobilephone
                  </small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={bookingClone.partner_representative_mobilephone || ''}
                  onClick={(ev) => (ev.target as HTMLInputElement).select()}
                />
              </div>
            </div>
          )}

          <div className="row">
            <Input
              type="text"
              label="Tier"
              extraClassName="col"
              disabled={true}
              hasChanged={false}
              value={booking.product_tier || ''}
            />

            <Input
              type="text"
              label="Shoot name"
              extraClassName="col"
              hasChanged={bookingChangedKeys.name}
              value={bookingClone.name || ''}
              onChange={({ target: { value } }) =>
                onNameChange({ name: value })
              }
            />
          </div>

          <div className="row">
            <div className="form-group col">
              <label>
                <small className="text-secondary">UID</small>
              </label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={bookingClone.uid || ''}
                onClick={(ev) => (ev.target as HTMLInputElement).select()}
              />
            </div>
            {callMaskingGetSessionAsReadOnlyMutation.loading === true ? null : (
              <div className="form-group col">
                {callMaskingGetSessionAsReadOnlyMutation.data
                  ?.providerCallMaskingGetOrCreateSession
                  ?.providerCallMaskingEnabled === false && (
                  <div
                    className="alert alert-primary"
                    role="alert"
                    style={{ marginBottom: 0 }}
                  >
                    Call masking is not active for this shoot.
                  </div>
                )}
              </div>
            )}
          </div>

          {booking.file_request_destination && (
            <div className="row align-items-end">
              <div className="form-group col">
                <label>
                  <small className="text-secondary">
                    {booking.file_request_service === 'box' ? 'Box' : 'Dropbox'}{' '}
                    folder
                  </small>
                </label>

                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={booking.file_request_destination}
                    onClick={(ev) => (ev.target as HTMLInputElement).select()}
                  />

                  <div className="input-group-append">
                    <a
                      href={
                        booking.file_request_service === 'box'
                          ? boxFolderUrl({
                              folderId: booking.file_request_destination,
                            }).url
                          : `https://www.dropbox.com/home${booking.file_request_destination}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-outline-primary"
                    >
                      Go
                    </a>
                  </div>
                </div>
              </div>

              <div className="form-group col">
                <label>
                  <small className="text-secondary">Editor</small>
                </label>

                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    // TODO: add editor name
                    value={booking.editor_name}
                    onClick={(ev) => (ev.target as HTMLInputElement).select()}
                  />

                  <div className="input-group-append">
                    <Link
                      to={`${rootBasePath}/${booking.uid}/gallery-upload`}
                      className="btn btn-outline-primary"
                    >
                      Upload
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}

          {tags != null && tags.length > 0 && (
            <div className="form-group flex-1 d-flex flex-column">
              <label>
                <small className="text-secondary">Tags</small>
              </label>

              <PillBadgeList
                items={tags?.map((tag) => tag.name)}
                backgroundColor="#F6F9FF"
              />
            </div>
          )}

          <div className="form-group flex-1 d-flex flex-column">
            <label>
              <small className="text-secondary">Internal Notes</small>
            </label>

            <textarea
              rows={2}
              name="internal_notes"
              className={`form-control flex-1 ${
                bookingChangedKeys.internal_notes ? 'is-valid' : ''
              }`}
              value={bookingClone.internal_notes || ''}
              onChange={({ target: { value } }) =>
                onInternalNotesChange({ internal_notes: value })
              }
            />
          </div>

          <div className="d-flex">
            <div className="form-check flex-1 d-flex flex-column">
              <label className="form-check-label">
                <input
                  name="gallery_enabled"
                  type="checkbox"
                  style={{ position: 'relative', top: 2, right: 8 }}
                  className={`form-check-input flex-1 ${
                    bookingChangedKeys.gallery_enabled ? 'is-valid' : ''
                  }`}
                  checked={bookingClone.gallery_enabled || false}
                  onChange={() =>
                    onGalleryEnabledChange({
                      gallery_enabled:
                        booking.gallery_enabled == null &&
                        bookingClone.gallery_enabled === true
                          ? null
                          : !bookingClone.gallery_enabled,
                    })
                  }
                />

                <small className="text-secondary">Public gallery</small>
              </label>
            </div>

            <div className="px-3"></div>

            <div className="form-check flex-1 d-flex flex-column">
              <label className="form-check-label">
                <input
                  name="file_request_service"
                  type="checkbox"
                  style={{ position: 'relative', top: 2, right: 8 }}
                  className={`form-check-input flex-1 ${
                    bookingChangedKeys.centralize_editing_enabled
                      ? 'is-valid'
                      : ''
                  }`}
                  checked={bookingClone.centralize_editing_enabled || false}
                  onChange={() =>
                    onCentralizeEditingChange({
                      centralize_editing_enabled:
                        booking.centralize_editing_enabled == null &&
                        bookingClone.centralize_editing_enabled === true
                          ? null
                          : !bookingClone.centralize_editing_enabled,
                    })
                  }
                />

                <small className="text-secondary">Enable central editing</small>
              </label>
            </div>

            {isPartnerBooking({ booking: bookingClone }) && (
              <>
                <div className="px-3"></div>

                <div className="form-check flex-1 d-flex flex-column">
                  <label className="form-check-label">
                    <input
                      name="pilot_mode"
                      type="checkbox"
                      style={{ position: 'relative', top: 2, right: 8 }}
                      className={`form-check-input flex-1 ${
                        bookingChangedKeys.pilot_mode_enabled_at
                          ? 'is-valid'
                          : ''
                      }`}
                      checked={bookingClone.pilot_mode_enabled_at ?? false}
                      onChange={() =>
                        onBookingPilotModeChange({
                          pilot_mode_enabled_at:
                            bookingClone.pilot_mode_enabled_at != null
                              ? null
                              : booking.pilot_mode_enabled_at ??
                                moment().toISOString(),
                        })
                      }
                    />
                    <small className="text-secondary">Enable pilot mode</small>
                  </label>
                </div>
              </>
            )}

            {(booking?.centralize_editing_enabled === true ||
              booking?.video_upload_file_enabled === true) && (
              <div className="form-check flex-1 d-flex flex-column">
                <label className="form-check-label">
                  <input
                    name="video_file_request_service"
                    type="checkbox"
                    style={{ position: 'relative', top: 2, right: 8 }}
                    className={`form-check-input flex-1 ${
                      bookingChangedKeys?.video_upload_file_enabled
                        ? 'is-valid'
                        : ''
                    }`}
                    checked={bookingClone?.video_upload_file_enabled || false}
                    onChange={() =>
                      onEnableVideoUploadChange({
                        video_upload_file_enabled:
                          booking?.video_upload_file_enabled == null &&
                          bookingClone?.video_upload_file_enabled === true
                            ? null
                            : !bookingClone?.video_upload_file_enabled,
                      })
                    }
                  />
                  <small className="text-secondary">Enable video upload</small>
                </label>
              </div>
            )}
          </div>
        </div>
      </div>

      <ModalRoute
        modalName={SALESAGENT_BOOKING_CLAIM_MODAL_NAME}
        render={(props) => (
          <SalesagentBookingClaimModal
            {...props}
            bookingClone={bookingClone}
            session={session}
          />
        )}
      />
    </div>
  );
};
